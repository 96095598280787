import './top.css';
const TopHeader = () => {
    return (
        <>
            <div className="site-nav">
                <div className="site-nav-center">
                    <ul className="site-nav-center-l">
                        <li>
                            <a href=""> 中国大陆</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                            <div className="site-nav-menu">
                                <ul>
                                    <li>中国大陆</li>
                                </ul>
                            </div>

                        </li>
                        <li><a href="" style={{color: "#f22e00"}}>亲，请登录</a></li>
                        <li><a href="">免费注册</a></li>
                        {/* <li><a href="">手机逛淘宝</a></li> */}
                    </ul>
                    <ul className="site-nav-center-r" style={{display:'none'}}>
                        <li>
                            <a href="">我的淘宝</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                            <div className="site-nav-menu">
                                <ul>
                                    <li>已买到的宝贝</li>
                                    <li>我的足迹</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-gouwuchekong" className=" gouwuche"></use> */}
                            </svg>
                            <a href="">购物车</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                        </li>
                        <li>
                            <a href="">收藏夹</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                            <div className="site-nav-menu">
                                <ul>
                                    <li>收藏的宝贝</li>
                                    <li>收藏的店铺</li>
                                </ul>
                            </div>
                        </li>
                        <li><a href="">商品分类</a></li>
                        <li><a href="">免费开店</a></li>
                        <li>
                            <a href="">千牛卖家中心</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                            <div className="site-nav-menu">
                                <ul>
                                    <li>免费开店</li>
                                    <li>已卖出的宝贝</li>
                                    <li>出售中的宝贝</li>
                                    <li>卖家服务市场</li>
                                    <li>卖家培训中心</li>
                                    <li>体验中心</li>
                                    <li>问商友</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="">联系客服</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                        </li>
                        <li>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-gengduo" className="gengduo"></use> */}
                            </svg>
                            <a href="">网站导航</a>
                            <svg className="icon" aria-hidden="true">
                                {/* <use xlink:href="#icon-xiangxia"></use> */}
                            </svg>
                            <div className="site-nav-menu" id="wangzhan">
                                <ul>
                                    <li style={{color: "#fc4200;"}}>
                                    主题市场
                                        <div className="wangzhi-detail">
                                            <ul>
                                                <li>
                                                    <li>卖家培训中心</li>
                                                    <li>体验中心</li>
                                                    <li>问商友</li>
                                                    <li>卖家服务市场</li>
                                                    <li>卖家培训中心</li>
                                                    <li>体验中心</li>
                                                    <li>问商友</li>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li style={{color:"#a6bd46"}}>特色市场</li>
                                    <li style={{color:"#d75696"}}>阿里App</li>
                                    <li style={{color:"#40a7de"}}>精彩推荐集</li>
                                    <li>卖家培训中心</li>
                                    <li>体验中心</li>
                                    <li>问商友</li>
                                    <li>卖家服务市场</li>
                                    <li>卖家培训中心</li>
                                    <li>体验中心</li>
                                    <li>问商友</li>
                                    <li>卖家服务市场</li>
                                    <li>卖家培训中心</li>
                                    <li>体验中心</li>
                                    <li>问商友</li>
                                </ul>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    )
}

export default TopHeader;