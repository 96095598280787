import React from 'react';
import ProductDetail from '../ProductDetail';

const Product = () => {
  return (
    <div className="App">
      <ProductDetail />
    </div>
  );
};

export default Product;