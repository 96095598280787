// import left from './img/mpp-left.jpg';
import right from './img/s1.jpg';
import left from './img/s2.jpg';

import React from 'react';
import { Carousel } from 'antd';
const contentStyle = {
    height: '80px',
    lineHeight: '80px',
    textAlign: 'center',
};
const Ad = () => {

    return (<>
        <Carousel autoplay>
            <div style={contentStyle}>
                <img  src={left} />
            </div>
            <div style={contentStyle}>
                <img src={right} />
            </div>

        </Carousel>
    </>);
};

export default Ad;