import Categories from "./Categories";
import FloorList from "./FloorList";

const HomePage = () => {
    return (<>
        <Categories />

        <div className='layout-home-floor'>
            <div className='floor-box'>
                <FloorList />
            </div>
        </div>
    </>)
};

export default HomePage;